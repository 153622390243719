/* eslint-disable require-jsdoc */

import {
	isElement,
	EventEmitter,
	setContent,
	addClassesString,
	merge
} from 'datatalks-utils';

import { Button } from 'datatalks-ui';

import { getIcon } from 'datatalks-icons';

export default class Popup {
	constructor(opts = {}) {
		const defaults = {
			classPrefix: 'eb-',
			cssClass: 'popup',
			extendedClasses: '',
			title: null,
			content: null,
			contentExtendedClasses: '',
			closeIcon: getIcon('close-line', { size: 'md' }),
			useCloseButton: true,
			preventCloseButtonDefault: false,
			startOpen: false,
			centerPopup: false
		};

		this.opts = merge(defaults, opts);
		this.eventEmitter = new EventEmitter();
		this.className = `${this.opts.classPrefix}${this.opts.cssClass}`;

		this.init();
	}

	init() {
		this.element = document.createElement('div');
		this.element.className = `${this.className}${
			this.opts.extendedClasses ? ' ' + this.opts.extendedClasses : ''
		}`;

		if (this.opts.centerPopup) {
			this.element.classList.add(`${this.className}--center`);
		}

		if (this.opts.title || this.opts.useCloseButton) this.createHeader();

		if (this.opts.startOpen) {
			this.open();
		} else {
			this.close();
		}

		this.content = addClassesString(
			setContent(document.createElement('div'), this.opts.content),
			[
				`${this.className}__content`,
				this.opts.contentExtendedClasses
			].join(' ')
		);

		this.draw();
		this.eventEmitter.emit('init', this);
	}

	draw() {
		setContent(this.element, [this.header, this.content]);
	}

	createHeader() {
		this.header = document.createElement('div');
		this.header.className = `${this.className}__header`;

		this.titleWrapper = document.createElement('div');
		this.titleWrapper.className = `${this.className}__title`;

		this.closeWrapper = document.createElement('div');
		this.closeWrapper.className = `${this.className}__close`;

		this.closeButton = new Button({
			content: this.opts.closeIcon,
			buttonVariant: 'gray',
			buttonSize: 'sm',
			buttonShape: 'square',
			onClick: () => {
				if (!this.opts.preventCloseButtonDefault) this.close();
			}
		}).getEl();

		setContent(this.header, [
			this.opts.title
				? setContent(this.titleWrapper, this.opts.title)
				: this.titleWrapper,
			this.opts.useCloseButton
				? setContent(this.closeWrapper, this.closeButton)
				: null
		]);
	}

	toggle() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	open() {
		this.element.classList.add(this.className + '--open');
		this.isOpen = true;
		this.eventEmitter.emit('open', this);
	}

	close() {
		this.element.classList.remove(this.className + '--open');
		this.isOpen = false;
		this.eventEmitter.emit('close', this);
	}

	setTitle(title) {
		setContent(this.titleWrapper, title);
	}

	getTitle() {
		return this.titleWrapper.innerHTML;
	}

	getContent() {
		return this.content.childNodes;
	}

	getEl() {
		return this.element;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}

	destroy() {
		this.eventEmitter.clear();
		this.element.remove();
	}
}
