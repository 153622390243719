/* eslint-disable require-jsdoc */
export default emailBuilder => {
	const options = emailBuilder.getOptions();

	let objects = [];

	if (options.dynamicLinks?.length) {
		objects = objects.concat(
			options.dynamicLinks.map(link => {
				return {
					contentType: 'link',
					position: link.position || 'top',
					iconName: link.icon,
					items: link.values.map(item => {
						return {
							content: item.label,
							value: item.href.url
						};
					})
				};
			})
		);
	}

	if (options.dynamicValues?.length) {
		objects = objects.concat(
			options.dynamicValues.map(button => {
				return {
					position: button.position || 'top',
					iconName: button.icon,
					items: button.values.map(item => {
						return {
							content: item.label,
							value: item.value
						};
					})
				};
			})
		);
	}

	emailBuilder.addOptions({
		textEditorOptions: {
			toolbarOptions: {
				objects
			}
		}
	});
};
