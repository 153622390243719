/* eslint-disable require-jsdoc */
import {
	merge,
	addClassesString,
	setContent,
	EventEmitter,
	isElement
} from 'datatalks-utils';
import { Dropdown } from 'datatalks-ui';

export default class TextEditorObject {
	constructor(options = {}) {
		const defaults = {
			getContent: (object, trait) => trait.getValue(),
			trait: null,
			className: 'text-editor-object',
			preventDefault: false
		};
		this.options = merge(defaults, options);

		this.eventEmitter = new EventEmitter();

		this.getContent = this.options.getContent;
		this.trait = this.options.trait;

		if (typeof this.getContent === 'function') this.init();
	}

	init() {
		this.createWrapper();
	}

	createWrapper() {
		this.wrapper = document.createElement('div');
		this.wrapper.className = this.options.className;
		this.updateContent();
		this.wrapper.addEventListener('click', () => this.trait.open());
	}

	updateContent() {
		this.currentContent = this.getContent(this, this.trait);
		setContent(this.wrapper, this.currentContent);
		this.eventEmitter.emit('contentUpdated', this.currentContent);
	}

	trigger() {
		this.eventEmitter.emit('trigger');
		if (!this.options.preventDefault) this.updateContent();
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}

export class TextEditorObjectCreator {
	constructor(options = {}) {
		const defaults = {
			trigger: null,
			triggerMode: 'click',
			objectOptions: {},
			startActive: false,
			traitOptions: {}
		};
		this.options = merge(defaults, options);

		this.triggerMode = this.options.triggerMode;
		this.isActive = this.options.startActive;
		this.eventEmitter = new EventEmitter();

		this.init();
	}

	init() {
		this.createTrait();
		this.createObject();
		this.createTrigger();
	}

	createTrigger() {
		if (
			isElement(this.options.trigger) &&
			this.options.trigger.tagName === 'BUTTON'
		) {
			this.trigger = this.options.trigger;
		} else {
			this.trigger = setContent(
				document.createElement('button'),
				this.options.trigger
			);
		}
		this.trigger.addEventListener(this.triggerMode, () =>
			this.object.trigger()
		);
	}

	createTrait() {
		this.trait = new TextEditorObjectTrait(this.options.traitOptions);
	}

	createObject() {
		this.object = new TextEditorObject({
			...this.options.objectOptions,
			trait: this.trait
		});
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}

export class TextEditorObjectTrait {
	constructor(options = {}) {
		const defaults = {
			type: 'dropdown',
			options: {},
			parent: null,
			append: false
		};
		this.options = merge(defaults, options);

		this.eventEmitter = new EventEmitter();

		this.trait = null;

		this.init();
	}

	init() {
		switch (this.options.type) {
			case 'dropdown':
				this.trait = new DropdownTrait(this.options.options);
				break;
			default:
				console.warn(
					'Trait type not recognized. Available types: dropdown'
				);
				break;
		}
		if (this.trait && this.options.parent) this.draw();
	}

	getValue() {
		switch (this.options.type) {
			case 'dropdown':
				return this.trait.dropdown.activeItem
					? this.trait.dropdown.activeItem.getValue()
					: this.trait.dropdown.defaultItem.getValue();
				break;
			default:
				console.warn(
					'Trait type not recognized. Available types: dropdown'
				);
				break;
		}
	}

	getEl() {
		return this.trait.getEl();
	}

	draw() {
		if (this.options.parent) {
			if (this.options.append) {
				this.options.parent.appendChild(this.trait.getEl());
			} else {
				setContent(this.options.parent, this.trait.getEl());
			}
			this.eventEmitter.emit('draw', this, this.options.parent);
		}
	}

	open() {
		if (!this.preventDrawOnOpen) this.draw();
		this.eventEmitter.emit('open', this);
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}

export class DropdownTrait {
	constructor(options = {}) {
		const defaults = {
			prefix: 'eb-',
			cssClass: 'dropdown-trait',
			extendedClasses: '',
			items: [],
			defaultItem: null,
			dropdownOptions: {},
			onChange: null,
			label: null,
			labelExtendedClasses: ''
		};
		this.options = merge(defaults, options);

		this.prefix = this.options.prefix;
		this.cssClass = this.options.cssClass;
		this.className = `${this.prefix}${this.cssClass}`;

		this.dropdown = null;
		this.label = null;
		this.element = null;

		this.init();
	}

	init() {
		this.createDropdown();
		if (this.options.label) this.createLabel();
		this.createWrapper();
		this.createWrapper();
	}

	createWrapper() {
		this.element = document.createElement('div');
		this.element.className = this.className;
		if (this.options.extendedClasses)
			addClassesString(this.element, this.options.extendedClasses);
		setContent(
			this.element,
			[this.label, this.dropdown.getEl()].filter(e => !!e)
		);
	}

	createDropdown() {
		const trait = this;
		trait.dropdown = new Dropdown({
			resettable: true,
			items: trait.options.items,
			activeItem: trait.options.defaultItem || trait.options.items[0],
			onChange: trait.options.onChange,
			extendedClasses: `${this.className}__dropdown`,
			...trait.options.dropdownOptions
		});
	}

	createLabel() {
		this.label = addClassesString(
			setContent(document.createElement('label'), this.options.label),
			`${this.className}__label`
		);

		if (this.options.labelExtendedClasses)
			addClassesString(this.label, this.options.labelExtendedClasses);
	}

	getEl() {
		return this.element;
	}

	getValue() {
		return this.dropdown.activeItem.getValue();
	}
}
