/* eslint-disable require-jsdoc */
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { LinkNode } from './_LinkNode';
import { $toggleLink } from './_toggleLink';
import { mergeRegister } from '@lexical/utils';
import {
	$getSelection,
	$isElementNode,
	$isRangeSelection,
	COMMAND_PRIORITY_LOW,
	PASTE_COMMAND
} from 'lexical';
import { validateUrl, merge } from 'datatalks-utils';

export default function LinkPlugin(editor, options = {}) {
	const defaults = {
		validateUrl: false
	};

	options = merge(defaults, options);

	if (!editor.hasNodes([LinkNode])) {
		throw new Error('LinkPlugin: LinkNode not registered on editor');
	}
	return mergeRegister(
		editor.registerCommand(
			TOGGLE_LINK_COMMAND,
			payload => {
				if (payload === null) {
					$toggleLink(payload);
					return true;
				} else if (typeof payload === 'string') {
					if (
						!options.validateUrl ||
						validateUrl === undefined ||
						validateUrl(payload)
					) {
						$toggleLink({ url: payload });
						return true;
					}
					return false;
				} else {
					if (payload.target || payload.rel || payload.title) {
						const { url, target, rel, title } = payload;
						$toggleLink({
							url,
							attributes: { rel, target, title }
						});
						return true;
					} else {
						$toggleLink(payload);
						return true;
					}
				}
			},
			COMMAND_PRIORITY_LOW
		),
		editor.registerCommand(
			PASTE_COMMAND,
			event => {
				const selection = $getSelection();
				if (
					!$isRangeSelection(selection) ||
					selection.isCollapsed() ||
					!(event instanceof ClipboardEvent) ||
					event.clipboardData == null
				) {
					return false;
				}
				const clipboardText = event.clipboardData.getData('text');
				if (!validateUrl(clipboardText)) {
					return false;
				}
				// If we select nodes that are elements then avoid applying the link.
				if (!selection.getNodes().some(node => $isElementNode(node))) {
					editor.dispatchCommand(TOGGLE_LINK_COMMAND, clipboardText);
					event.preventDefault();
					return true;
				}
				return false;
			},
			COMMAND_PRIORITY_LOW
		)
	);

	return null;
}
