/**
 * The options to configure the Dropdown
 * @typedef { Object } DropdownOptions
 * @property { HTMLElement | HTMLString | string }  content - The inner content of the button.
 * @property { string= } classPrefix - A prefix to add to all the class names. @default ''
 * @property { string= } cssClass - The base CSS class of the Button. @default 'btn'
 * @property { string= } buttonVariant - The variant of the button to display. @default 'primary'
 * @property { boolean= } isOutline - Whether or not to use the outline version. @default false
 * @property { string= } extendedClasses - The CSS classes to extend the style of the
 * button. @default ''
 * @property { string= } outlineModifierString - The string used to modify the CSS class when the
 * outline version is used. @default 'outline'
 * @property { HTMLElement= } appendTo - The element to append the button to. Button will not be
 * drawn if it's not passed. @default null
 * @property { boolean= } active - Whether or not the button is in its active
 * state. @default false
 */

import { addClassesString, isElement, EventEmitter } from 'datatalks-utils';

/**
 * Class representing a Button.
 * @class
 * @property { ButtonOptions= }  options - The options to configure the Button with.
 */
export default class Button {
	/**
	 * Creates the needed properties to build the Button component. Calls init method.
	 * @constructor
	 * @param { ButtonOptions } options - The options to create the Button.
	 */
	constructor(options = {}) {
		const defaults = {
			classPrefix: 'eb-',
			cssClass: 'btn',
			buttonVariant: 'primary',
			buttonSize: 'md',
			buttonShape: null,
			isOutline: false,
			extendedClasses: '',
			outlineModifierString: 'outline',
			appendTo: null,
			content: null,
			active: false,
			isDisabled: false,
			onClick: null,
			onDisable: null,
			onEnable: null,
			onDraw: null,
			onInit: null // TODO: add docs
		};

		this.options = {
			...defaults,
			...options
		};
		this.eventEmitter = new EventEmitter();
		this.className = `${this.options.classPrefix}${this.options.cssClass}`;

		this.isActive = this.options.active;
		this.isDisabled = this.options.isDisabled;
		this.init();
	}

	/**
	 * Creates the button element, assigns it to element property and adds the attributes.
	 * Calls draw method.
	 * @method
	 */
	init() {
		this.element = document.createElement('button');
		this.element.className = `${this.className} ${this.className}--${
			this.options.buttonVariant
		}${this.options.isOutline ? '-outline' : ''} ${this.className}--${
			this.options.buttonSize
		}`;

		if (this.options.buttonShape) {
			this.element.classList.add(
				`${this.className}--${this.options.buttonShape}`
			);
		}

		this.element.addEventListener('click', e => {
			this.handleClick.call(this, e);
		});

		if (typeof this.options.onClick === 'function')
			this.on('click', this.options.onClick);

		if (typeof this.options.onDisable === 'function')
			this.on('disable', this.options.onDisable);

		if (typeof this.options.onEnable === 'function')
			this.on('enable', this.options.onEnable);

		if (typeof this.options.onDraw === 'function')
			this.on('draw', this.options.onDraw);

		if (typeof this.options.onInit === 'function')
			this.on('init', this.options.onInit);

		if (this.isActive) this.setActive();
		if (this.isDisabled) this.disable();

		if (this.options.extendedClasses)
			addClassesString(this.element, this.options.extendedClasses);

		if (this.options.content) this.setContent(this.options.content);

		if (this.options.appendTo) this.draw(this.options.appendTo);

		this.eventEmitter.emit('init', this);
	}

	/**
	 * sets the content of the button with the given content.
	 * @param { HTMLElement | HTMLString | string }  content - The new inner content of the button.
	 * @method
	 */
	setContent(content) {
		if (isElement(content)) {
			this.element.innerHTML = '';
			this.element.append(content);
		} else {
			this.element.innerHTML = content;
		}
	}

	/**
	 * Handles the click of the button element.
	 * @param { Event }  event - The click event.
	 * @method
	 */
	handleClick(event) {
		if (!this.isDisabled)
			this.eventEmitter.emit('click', this.element, event, this);
	}

	/**
	 * Draws the button in the given parent.
	 * @param { !HTMLElement }  parent - The element where the button should be drawn.
	 * @param { boolean= }  append - If true would append the button to the parent, else it would replace
	 * all the content with the button. @default true
	 * @method
	 */
	draw(parent, append = true) {
		if (append) {
			parent.append(this.element);
		} else {
			parent.innerHTML = this.element;
		}
		this.eventEmitter.emit('draw', this);
	}

	/**
	 * Triggers the click of the button element.
	 * @method
	 */
	click() {
		this.element.click();
	}

	/**
	 * Set the button to be in its active state or not.
	 * @param { boolean } active - If the button should be active @default true
	 * @method
	 */
	setActive(active = true) {
		if (active) {
			this.element.classList.add(`${this.className}--active`);
		} else {
			this.element.classList.remove(`${this.className}--active`);
		}
	}

	/**
	 * Set the button to be disabled.
	 * @method
	 */
	disable() {
		this.isDisabled = true;
		this.element.setAttribute('disabled', true);
		this.eventEmitter.emit('disable', this);
	}

	/**
	 * Checks whether the button is disabled.
	 * @return { boolean } Whether or not the button is disabled
	 * @method
	 */
	getDisabled() {
		return this.isDisabled;
	}

	/**
	 * Set the button to not be disabled.
	 * @method
	 */
	enable() {
		this.isDisabled = false;
		this.element.removeAttribute('disabled');
		this.eventEmitter.emit('enable', this);
	}

	/**
	 * Returns the button element.
	 * @return { HTMLElement } The button element
	 * @method
	 */
	getEl() {
		return this.element;
	}

	/**
	 * Subscribes to the event by adding the given callback as a listener
	 * of the passed event.
	 * @param { !string } event - The event to add the listener to.
	 * @param { function } callback - The callback to call when
	 * the event is triggered
	 * @method
	 */
	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	/**
	 * Unsubscribes to the event by removing the given callback from the
	 * listeners of the passed event.
	 * @param { !string } event - The event to removed the listener from
	 * @param { function } callback - The callback to remove
	 * @method
	 */
	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}
