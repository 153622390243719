// import { noInteractionPreset } from '../_componentsUtils';
import { COMPONENTS_DEFAULTS } from '../../../config/_config';
import headerTrait from './trait/_trait';
import {
	arrayMove,
	arrayRemove,
	getElementWidthPercentage,
	fileReader,
	merge
} from 'datatalks-utils';
import render from './_render';
import baseMethods from '../common/methods/_baseMethods';
import { getComponentsDefaultsByType } from '../../componentsDefaults/_componentsDefaults';
import toHtml from './_toHtml';

export default (componentOptions = {}, emailBuilder) => {
	const { editor } = emailBuilder;
	const defaults = {
		attributes: {},
		align: 'center',
		vAlign: 'middle',
		spacingBetweenElements: '12px',
		cssClass: 'gjs-comp-header',
		width: '100%',
		imageAlignment: 'center',
		imgPh: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+',
		imageWidth: '25%',
		linksList: [
			{
				content: 'Title',
				href: 'http://www.title.com',
				target: '_blank'
			},
			{
				content: 'Title',
				href: 'http://www.title.com',
				target: '_blank'
			},
			{
				content: 'Title',
				href: 'http://www.title.com',
				target: '_blank'
			},
			{
				content: 'Title',
				href: 'http://www.title.com',
				target: '_blank'
			}
		],
		isTextList: false,
		newLink: {
			content: 'Title',
			href: 'http://www.title.com',
			target: '_blank'
		},
		addLinkCallback: null,
		tagName: 'table',
		useImagePlaceholderComponent: false,
		linksListWidth: '60%',
		linksListAlign: 'center',
		linksBorderRadius: {
			topLeft: 0,
			topRight: 0,
			bottomRight: 0,
			bottomLeft: 0
		},
		linksUseBorderRadius: false,
		linksBorderColor: 'transparent',
		linksBorderStyle: 'solid',
		linksBorderWidth: 0,
		linksColor: null,
		linksBackground: 'transparent',
		linksGap: '20px',
		useLinksGap: false,
		linksFont: null,
		linksWeight: 'normal',
		linksLetterSpacing: 'normal',
		linksNormalLetterSpacing: true,
		linksDefaultLetterSpacingLength: '0.1em',
		name: 'Header',
		displayImage: true,
		displayLinks: true,
		imageBorderRadius: {
			topLeft: 0,
			topRight: 0,
			bottomRight: 0,
			bottomLeft: 0
		},
		imageUseBorderRadius: false,
		imageBorderColor: 'transparent',
		imageBorderStyle: 'solid',
		imageBorderWidth: 0,
		imageHasLink: false,
		imageHref: null,
		layoutVariant: 1,
		toHtmlOptions: {}
	};

	const options = merge(
		COMPONENTS_DEFAULTS,
		getComponentsDefaultsByType(),
		defaults,
		componentOptions
	);

	return {
		model: {
			defaults: () => ({
				isCompound: true,
				...options,
				attributes: {
					...options.attributes,
					align: options.align,
					class: options.cssClass
				},
				style: {
					width: options.width
				},
				imgSrc: options.useImagePlaceholderComponent
					? null
					: options.imgPh,
				linksList: [...options.linksList],
				// TODO: document GrapesJS array issue
				linksNormalLetterSpacing:
					options.linksLetterSpacing === 'normal',
				components: comp => comp.componentRender(comp),
				traits(component) {
					const result = [headerTrait()];

					return result;
				}
			}),

			init() {
				this.setStyle({
					...this.getStyle(),
					width: this.get('width')
				});
			},

			toHTML(toHtmlOptions = {}) {
				return toHtml(
					this,
					merge(options.toHtmlOptions, toHtmlOptions)
				);
			},

			setAndRerender(attr, value) {
				this.set(attr, value);
				this.components(this.componentRender(this));
			},

			setNormalLetterSpacing(isNormal) {
				this.set('linksNormalLetterSpacing', isNormal);
			},

			setLetterSpacing(value) {
				this.setNormalLetterSpacing(value === 'normal');
				this.setAndRerender('linksLetterSpacing', value);
				this.components(this.componentRender(this));
			},

			addLink(linkOptions) {
				const newLink = {
					...this.get('newLink'),
					...linkOptions
				};
				this.get('linksList').push(newLink);
				this.components(this.componentRender(this));
				if (typeof options.addLinkCallback === 'function')
					options.addLinkCallback.call(this, this, newLink);
				return newLink;
			},

			deleteLink(item) {
				arrayRemove(this.get('linksList'), item);
				this.components(this.componentRender(this));
				if (typeof options.deleteLinkCallback === 'function')
					options.deleteLinkCallback.call(this, this);
			},

			changeLinksOrder(oldIndex, newIndex) {
				arrayMove(this.get('linksList'), oldIndex, newIndex);
				this.components(this.componentRender(this));
			},

			changeLinkText(item, text, rerender = true) {
				this.set(
					'linksList',
					this.get('linksList').map(link => {
						return link === item
							? { ...link, content: text }
							: link;
					})
				);
				if (rerender) this.components(this.componentRender(this));
			},

			getItemProp(item, prop) {
				const _item = this.get('linksList').find(link => {
					return link === item;
				});
				return _item ? _item[prop] : null;
			},

			setItemProp(item, prop, value, rerender = true) {
				const _item = this.get('linksList').find(link => {
					return link === item;
				});
				if (_item) _item[prop] = value;
				else console.warn('Item not found');

				if (rerender) this.components(this.componentRender(this));
			},

			changeLinkUrl(item, url) {
				this.get('linksList').filter(link => {
					return link === item;
				})[0].href = url;
				this.components(this.componentRender(this));
			},

			changeLinksColor(color) {
				const comp = this;
				comp.set('linksColor', color);
				comp.components(comp.componentRender(comp));
			},

			setLinksBorderRadius(corner, value) {
				const aux = {};
				if (
					[
						'topLeft',
						'topRight',
						'bottomLeft',
						'bottomRight'
					].includes(corner)
				) {
					aux[corner] = value;
					this.set('linksBorderRadius', {
						...this.get('linksBorderRadius'),
						...aux
					});
					this.components(this.componentRender(this));
				} else {
					const br = { ...this.get('linksBorderRadius') };
					for (const key in br) {
						if (br.hasOwnProperty(key)) aux[key] = value;
					}
					this.set('linksBorderRadius', { ...aux });
					this.components(this.componentRender(this));
				}
			},

			changeLinkOpenMode(item, target) {
				this.get('linksList').filter(link => {
					return link === item;
				})[0].target = target;
				this.components(this.componentRender(this));
			},

			changeImageWidth(width) {
				this.set('imageWidth', width);
				this.components(this.componentRender(this));
			},

			changeLinksWidthToPercentage() {
				const linksList = this.findType('links-list')[0];
				if (linksList) {
					const linksListEl = linksList.getEl();
					if (linksListEl)
						this.changeLinksListWidth(
							getElementWidthPercentage(linksListEl)
						);
				}
			},

			changeLinksListWidth(width) {
				this.set('linksListWidth', width);
				this.components(this.componentRender(this));
			},

			changeLinksGap(value) {
				this.set('linksGap', value);
				this.components(this.componentRender(this));
			},

			changeImage(file, callback) {
				const comp = this;
				if (file instanceof File || file instanceof Blob) {
					fileReader(file, {
						callbacks: {
							onload: result => {
								comp.set('imgSrc', result.e.target.result);
								comp.components(comp.componentRender(comp));
							},
							dataRead:
								typeof callback === 'function'
									? callback
									: undefined,
							onError: console.error,
							onabort: console.log
						}
					});
				} else {
					comp.set('imgSrc', file);
					comp.components(comp.componentRender(comp));
				}
			},

			removeImage() {
				const comp = this;
				comp.set('imgSrc', '');
				comp.components(comp.componentRender(comp));
			},

			setImageBorderRadius(corner, value) {
				const aux = {};
				if (
					[
						'topLeft',
						'topRight',
						'bottomLeft',
						'bottomRight'
					].includes(corner)
				) {
					aux[corner] = value;
					this.set('imageBorderRadius', {
						...this.get('imageBorderRadius'),
						...aux
					});
					this.components(this.componentRender(this));
				} else {
					const br = { ...this.get('imageBorderRadius') };
					for (const key in br) {
						if (br.hasOwnProperty(key)) aux[key] = value;
					}
					this.set('imageBorderRadius', { ...aux });
					this.components(this.componentRender(this));
				}
			},

			hideImage() {
				this.set('displayImage', false);
				this.components(this.componentRender(this));
			},

			showImage() {
				this.set('displayImage', true);
				this.components(this.componentRender(this));
			},

			toggleImage() {
				this.set('displayImage', !this.get('displayImage'));
				this.components(this.componentRender(this));
			},

			hideLinks() {
				this.set('displayLinks', false);
				this.components(this.componentRender(this));
			},

			showLinks() {
				this.set('displayLinks', true);
				this.components(this.componentRender(this));
			},

			toggleLinksDisplay() {
				this.set('displayLinks', !this.get('displayLinks'));
				this.components(this.componentRender(this));
			},

			componentRender: render,
			...baseMethods(componentOptions, editor)
		}
	};
};
