import { $isLinkNode, $isAutoLinkNode } from '@lexical/link';
import { $createLinkNode } from './_LinkNode'; // TODO: Implement this function
import { $getSelection, $isRangeSelection, $isElementNode } from 'lexical';

/**
 * Toggles the link state of the selected text in the editor.
 *
 * @param {Object} options - The options for toggling the link.
 * @param {Object} options.attributes - The attributes for the link.
 * @param {string} options.attributes.rel - The rel attribute for the link.
 * @param {string} options.attributes.target - The target attribute for the link.
 * @param {string} options.attributes.title - The title attribute for the link.
 * @param {string} options.url - The URL for the link.
 * @param {Object} options.editorState - The current state of the editor.
 *
 * @return {void}
 */
export function $toggleLink(options) {
	const attributes = options.attributes || {};
	const { url, editorState } = options;
	const { target, title } = attributes;
	const rel = attributes.rel === undefined ? 'noreferrer' : attributes.rel;
	const selection = $getSelection();

	if (!$isRangeSelection(selection)) {
		return;
	}
	const nodes = selection.extract();

	if (url === null) {
		// Remove LinkNodes
		nodes.forEach(node => {
			const parent = node.getParent();

			if (!$isAutoLinkNode(parent) && $isLinkNode(parent)) {
				const children = parent.getChildren();

				for (let i = 0; i < children.length; i++) {
					parent.insertBefore(children[i]);
				}

				parent.remove();
			}
		});
	} else {
		// Add or merge LinkNodes
		if (nodes.length === 1) {
			const firstNode = nodes[0];
			// if the first node is a LinkNode or if its
			// parent is a LinkNode, we update the URL, target and rel.
			const linkNode = $getAncestor(firstNode, $isLinkNode);
			if (linkNode !== null) {
				linkNode.setURL(url);
				if (target !== undefined) {
					linkNode.setTarget(target);
				}
				if (rel !== null) {
					linkNode.setRel(rel);
				}
				if (title !== undefined) {
					linkNode.setTitle(title);
				}
				if (editorState && linkNode.addOptions) {
					linkNode.addOptions({
						editorState
					});
				}
				return;
			}
		}

		let prevParent = null;
		let linkNode = null;

		nodes.forEach(node => {
			const parent = node.getParent();

			if (
				parent === linkNode ||
				parent === null ||
				($isElementNode(node) && !node.isInline())
			) {
				return;
			}

			if ($isLinkNode(parent)) {
				linkNode = parent;
				parent.setURL(url);
				if (target !== undefined) {
					parent.setTarget(target);
				}
				if (rel !== null) {
					linkNode.setRel(rel);
				}
				if (title !== undefined) {
					linkNode.setTitle(title);
				}
				return;
			}

			if (!parent.is(prevParent)) {
				prevParent = parent;
				linkNode = $createLinkNode({
					url,
					attributes: { rel, target, title },
					editorState
				});

				if ($isLinkNode(parent)) {
					if (node.getPreviousSibling() === null) {
						parent.insertBefore(linkNode);
					} else {
						parent.insertAfter(linkNode);
					}
				} else {
					node.insertBefore(linkNode);
				}
			}

			if ($isLinkNode(node)) {
				if (node.is(linkNode)) {
					return;
				}
				if (linkNode !== null) {
					const children = node.getChildren();

					for (let i = 0; i < children.length; i++) {
						linkNode.append(children[i]);
					}
				}

				node.remove();
				return;
			}

			if (linkNode !== null) {
				linkNode.append(node);
			}
		});
	}
}

/**
 * Traverses up the Editor state tree from the given node until it finds an ancestor that matches the predicate.
 *
 * @param {Node} node - The starting node to begin the search from.
 * @param {function(Node): boolean} predicate - A function that tests each ancestor node.
 * @return {Node|null} - The first ancestor node that matches the predicate, or null if no matching ancestor is found.
 */
function $getAncestor(node, predicate) {
	let parent = node;
	while (
		parent !== null &&
		parent.getParent() !== null &&
		!predicate(parent)
	) {
		parent = parent.getParentOrThrow();
	}
	return predicate(parent) ? parent : null;
}
