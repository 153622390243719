/* eslint-disable require-jsdoc */
import { LinkNode as LexicalLink } from '@lexical/link';
import { merge } from 'datatalks-utils';

export class LinkNode extends LexicalLink {
	static getType() {
		return 'link-node';
	}

	static clone(node) {
		return new LinkNode(node.__options, node.__key);
	}

	constructor(options = {}, key) {
		const defaults = {
			editorState: null,
			attributes: {},
			url: ''
		};
		options = merge(defaults, options);
		super(options.url, options.attributes, key);
		this.__options = options;
	}

	setOptions(options) {
		const self = this.getWritable();
		self.__options = options;
	}

	getOptions() {
		const self = this.getLatest();
		return self.__options;
	}

	addOptions(options) {
		const self = this.getWritable();
		self.__options = merge(self.__options, options);
	}

	exportJSON() {
		return {
			...super.exportJSON(),
			type: 'link-node',
			options: this.__options
		};
	}

	static importJSON(json) {
		const { options } = json;
		return new LinkNode(options);
	}
}

export function $createLinkNode(options) {
	return new LinkNode(options);
}

export function $isLinkNode(node) {
	return node instanceof LinkNode;
}

export default LinkNode;
